<template>
  <div>
    <Pane />

    <div class="container">
      <div class="content">
        <!-- @change="onTabChange" -->
        <a-tabs tab-position="left" v-model="activeTabKey">
          <a-tab-pane key="1" tab="设计输入参数">
            <a-table bordered :dataSource="publicParamList" :pagination="false">
              <a-table-column title="序号" width="50px" align="center">
                <template slot-scope="text, row, index">
                  {{ index + 1 }}
                </template>
              </a-table-column>
              <a-table-column title="中文名" data-index="name" />
              <a-table-column title="英文名" data-index="code" />

              <a-table-column title="设计值">
                <template slot-scope="text">
                  <!-- 文本输入框 -->
                  <span v-if="String(text.valueType) === '2'">
                    <a-input
                      size="small"
                      style="width: 120px"
                      :value="text.designValue"
                      @change="
                        (e) => {
                          onChange(e.target.value, text.code);
                        }
                      "
                    />
                  </span>

                  <span v-else-if="String(text.valueClass) === '1'">
                    <a-input-number
                      size="small"
                      style="width: 120px"
                      :value="text.designValue"
                      :precision="
                        typeof text.decimalPrecision === 'number'
                          ? text.decimalPrecision
                          : 0
                      "
                      @change="
                        (e) => {
                          onChange(e, text.code);
                        }
                      "
                    />
                  </span>
                  <span v-else>
                    {{ text.designValue }}
                  </span>
                </template>
              </a-table-column>
              <a-table-column title="类型" align="center">
                <template slot-scope="text">
                  <DataDictFinder
                    dictType="bim_value_type"
                    :dictValue="String(text.valueClass)"
                  />
                </template>
              </a-table-column>

              <a-table-column title="单位" data-index="unit" />
              <a-table-column title="参考信息" data-index="remark" />
            </a-table>
          </a-tab-pane>

          <a-tab-pane v-for="item in bookList" :key="item.id" :tab="item.name">
            <a-spin :spinning="loading">
              <a-tabs
                v-model="active"
                :tabBarStyle="{
                  margin: '0 0 12px 0',
                }"
              >
                <a-button
                  type="primary"
                  slot="tabBarExtraContent"
                  @click="queryParam"
                >
                  公式计算
                </a-button>
                <a-tab-pane
                  v-for="item in paramTypeList"
                  :key="item.value"
                  :tab="item.name"
                >
                </a-tab-pane>
              </a-tabs>

              <div v-if="Array.isArray(item.bimItemList)">
                <a-table
                  bordered
                  :dataSource="filtered(item.bimItemList)"
                  :pagination="{
                    total: filtered(item.bimItemList).length,
                    pageSize: 10,
                    showTotal: (total) => `共 ${total} 条记录`,
                  }"
                >
                  <a-table-column title="序号" width="50px" align="center">
                    <template slot-scope="text, row, index">
                      {{ index + 1 }}
                    </template>
                  </a-table-column>
                  <a-table-column title="中文名" data-index="name" />
                  <a-table-column title="英文名" data-index="code" />

                  <a-table-column title="设计值">
                    <template slot-scope="text">
                      <!-- 文本输入框 -->
                      <span v-if="String(text.valueType) === '2'">
                        <a-input
                          size="small"
                          style="width: 120px"
                          :value="text.designValue"
                          :disabled="text.disabled"
                          @change="
                            (e) => {
                              onChangeParam(e.target.value, item.id, text.id);
                            }
                          "
                        />
                      </span>

                      <span v-else-if="String(text.valueClass) === '1'">
                        <a-input-number
                          size="small"
                          style="width: 120px"
                          :value="text.designValue"
                          :disabled="text.disabled"
                          :precision="
                            typeof text.decimalPrecision === 'number'
                              ? text.decimalPrecision
                              : 0
                          "
                          @change="
                            (e) => {
                              onChangeParam(e, item.id, text.id);
                            }
                          "
                        />
                      </span>
                      <span v-else>
                        {{ text.designValue }}
                      </span>
                    </template>
                  </a-table-column>
                  <a-table-column title="类型" align="center">
                    <template slot-scope="text">
                      <DataDictFinder
                        dictType="bim_value_type"
                        :dictValue="String(text.valueClass)"
                      />
                    </template>
                  </a-table-column>

                  <a-table-column title="单位" data-index="unit" />
                  <a-table-column title="参考信息" data-index="remark" />
                </a-table>
              </div>
            </a-spin>
          </a-tab-pane>
        </a-tabs>

        <div class="center" style="margin-top: 40px">
          <a-space>
            <a-button
              type="primary"
              @click="handleSubmit"
              :loading="saveLoading"
            >
              {{
                !writePublicParam || bookList.length !== bookRawList.length
                  ? "下一步"
                  : "立即创建"
              }}
            </a-button>
            <a-button @click="$close($route.path)">关闭</a-button>
          </a-space>
        </div>
      </div>

      <div class="cart">
        <div class="header">
          <img src="../../../assets/param_icon.png" alt="" />
          <span>关联参数</span>
        </div>
        <div class="card" v-for="item in bookList" :key="item.id">
          <div class="title">{{ item.name }}</div>
          <div v-if="filteredValueClass(item.bimItemList).length > 0">
            <div
              class="between item"
              v-for="element in filteredValueClass(item.bimItemList)"
              :key="element.id"
            >
              <div class="label">{{ element.name }}</div>
              <div class="value">
                {{ element.designValue }}{{ element.unit }}
              </div>
            </div>
          </div>
          <div v-else>暂无数据</div>
        </div>
      </div>
    </div>

    <a-modal :visible="visible" title="选择状态" @ok="ok" @cancel="cancel">
      <a-select
        style="width: 320px"
        v-model="stateCode"
        placeholder="审批类型"
        mode="tags"
      >
        <a-select-option
          v-for="item in statusList"
          :key="item.value"
          :value="item.value"
          >{{ item.name }}</a-select-option
        >
      </a-select>
    </a-modal>
  </div>
</template>

<script>
import { fetchList, fetchValues } from "@/api/parameterization/param";
import { fetchDetail } from "@/api/parameterization/book";
import { add } from "@/api/parameterization/task";
import { mapGetters } from "vuex";
export default {
  name: "addParamTask",

  data() {
    return {
      bookStr: "", // 用户选择的计算书字符串
      bookRawList: [], // 计算书未处理列表
      publicParamList: [], // 公共参数列表
      activeTabKey: "1", // 选中的计算书 id，公共参数为 1，默认选中公共参数
      writePublicParam: false, // 是否已填完公共参数

      loading: false,

      bookList: [], // 计算书列表
      active: "5", // 计算书中的选中分类

      saveLoading: false,

      stateCode: [],
      visible: false,
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    typeList() {
      return this.findDataDict("bim_calc_book_type");
    },

    paramTypeList() {
      return this.findDataDict("bim_param_type");
    },

    statusList() {
      return this.findDataDict("bim_state_code");
    },
  },
  activated() {
    // 获取用户选择的计算书列表
    const bookStr = window.localStorage.getItem("selected-books");

    if (this.bookStr !== bookStr) {
      this.bookStr = bookStr;
      this.bookRawList = JSON.parse(bookStr);

      // 获取公共参数
      fetchList({
        paramType: 1,
        pageNum: 1,
        pageSize: 1000,
      }).then((res) => {
        console.log(res);
        if (res && Array.isArray(res.list)) {
          this.publicParamList = res.list;
        }
      });

      this.stateCode = this.statusList.map((item) => item.value); // 默认选中所有状态
    }
  },

  methods: {
    // onTabChange(v) {
    //   console.log(v);
    //   this.activeTabKey = v;
    //   if (v !== "1") {
    //     const obj = this.bookList.find((item) => item.id === v);
    //     if (!obj.bimItemList) {
    //       this.getDetail(v);
    //     }
    //   }
    // },

    getDetail(id) {
      return fetchDetail({ id }).then((res) => {
        if (res && Array.isArray(res.bimItemList)) {
          const index = this.bookList.findIndex((item) => item.id === id);
          const item = this.bookList[index];
          this.bookList.splice(index, 1, {
            ...item,
            bimItemList: res.bimItemList,
          });
        }

        // this.name =
        //   res.name +
        //   "_" +
        //   this.typeList.find((item) => item.value === res.type).name;
      });
    },

    onChange(e, code) {
      console.log(e, code);

      const index = this.publicParamList.findIndex(
        (item) => item.code === code
      );
      this.publicParamList.splice(index, 1, {
        ...this.publicParamList[index],
        designValue: e,
        manualModify: "1",
      });
    },

    onChangeParam(e, bookId, itemId) {
      console.log("e", e, bookId, itemId);
      const idx = this.bookList.findIndex((item) => item.id === bookId);
      const book = this.bookList[idx];
      const list = book.bimItemList;
      const index = list.findIndex((item) => item.id === itemId);

      list.splice(index, 1, {
        ...list[index],
        designValue: e,
        manualModify: "1",
      });

      this.bookList.splice(idx, 1, {
        ...book,
        bimItemList: list,
      });
    },

    // 根据分类渲染不同的参数，方便用户选择
    filtered(list) {
      if (this.active && Array.isArray(list)) {
        const arr = list.filter((item) => item.paramType === this.active);
        return arr;
      } else {
        return list;
      }
    },

    // 根据 valueClass 过滤出引用参数
    filteredValueClass(list) {
      if (Array.isArray(list)) {
        return list.filter((item) => item.valueClass === "3");
      } else {
        return [];
      }
    },

    queryParam() {
      fetchValues({
        pid: this.activeTabKey,
        list: [
          ...this.publicParamList,
          ...this.bookList.find((item) => item.id === this.activeTabKey)
            .bimItemList,
        ],
      }).then((res) => {
        console.log(res);
        if (res && Array.isArray(res.list)) {
          this.$message.success("查询成功");
          const index = this.bookList.findIndex(
            (item) => item.id === this.activeTabKey
          );
          const item = this.bookList[index];
          item.bimItemList = item.bimItemList.map((item) => {
            const obj = res.list.find((element) => element.code === item.code);
            return {
              ...item,
              designValue: obj.designValue,
              disabled:
                this.publicParamList.findIndex(
                  (publicParams) => publicParams.code === item.code
                ) > -1, // 计算书里面的参数如果是公共参数，那就禁用，不让用户改
            };
          });
          this.bookList.splice(index, 1, item);
        }
      });
    },

    async handleSubmit() {
      // 校验公共参数是否填完
      let hasDone = true;
      this.publicParamList.forEach((item) => {
        if (!item.designValue) {
          hasDone = false;
        }
      });

      if (!hasDone) {
        this.$message.error("请填写公共参数！");
        return;
      }

      // 公共参数刚填完
      if (!this.writePublicParam) {
        this.writePublicParam = true;
        const item = this.bookRawList[0];
        this.bookList.push(item);
        this.activeTabKey = item.id;
        this.loading = true;
        await this.getDetail(item.id);
        this.loading = false;
        this.queryParam();
        return;
      }

      // 还剩点计算书没填完
      if (this.bookList.length !== this.bookRawList.length) {
        const item = this.bookRawList[this.bookList.length];
        this.bookList.push(item);
        this.activeTabKey = item.id;
        this.loading = true;
        await this.getDetail(item.id);
        this.loading = false;
        this.queryParam();
        return;
      }

      let flag = false;
      for (let i = 0; i < this.bookList.length; i++) {
        const item = this.bookList[i];
        if (!Array.isArray(item.bimItemList)) {
          flag = true;
          break;
        }
        if (item.bimItemList.length === 0) {
          flag = true;
          break;
        }
      }
      if (flag) {
        this.$message.error("请输入参数");
        return;
      }

      this.visible = true;
    },

    ok() {
      if (!this.stateCode.length) {
        this.$message.error("请选择状态");
        return;
      }
      this.saveLoading = true;

      Promise.all([
        ...this.bookList.map((item) => {
          return add({
            name: item.name,
            bookId: item.id,
            bookTypeSub: item.typeSub,
            body: JSON.stringify([
              ...this.publicParamList,
              ...item.bimItemList,
            ]),
            stateCode: this.stateCode.join(","),
          });
        }),
      ])
        .then((res) => {
          console.log("res", res);
          let errorMessage = "";
          res.forEach((item) => {
            if (item.code === 400) {
              errorMessage = item.message;
            }
          });
          if (errorMessage) {
            this.$error({
              title: "提示",
              content: errorMessage,
            });

            return;
          }

          this.stateCode = this.statusList.map((item) => item.value);
          this.visible = false;
          const that = this;
          this.$success({
            title: "提示",
            content: "创建任务成功，请耐心等待系统通知！",
            onOk() {
              that.$router.push("/lab/parameterization/task");
            },
          });
        })
        .finally(() => {
          this.saveLoading = false;
        });
    },

    cancel() {
      this.visible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  display: grid;
  grid-template-columns: 1fr 240px;
  gap: 8px;

  & > div {
    background-color: #fff;
    padding: 16px;
  }

  .content {
    padding-left: 0;
  }
}

.cart {
  .header {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid #f0f0f0;

    img {
      width: 14px;
      margin-right: 8px;
    }
  }

  .card {
    border: 1px solid #e0e0e0;
    padding: 12px;
    margin-bottom: 12px;
    .title {
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      color: #1890ff;
      margin-bottom: 12px;
    }
    .item {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }

      .value {
        color: #1890ff;
      }
    }
  }
}
</style>
