import request from '../request'

export function fetchList(params) {
    return request({
        url: '/support-3rd/bim/calculateBook/list',
        params,
    })
}

// 查询不同分类下计算书的数量
export function fetchNumByCategory() {
    return request({
        url: '/support-3rd/bim/calculateBook/query/typeNum',
    })
}

export function fetchDetail(params) {
    return request({
        url: '/support-3rd/bim/calculateBook/query/' + params.id,
    })
}

export function add(data) {
    return request({
        url: '/support-3rd/bim/calculateBook/add',
        method: 'post',
        data,
    })
}

export function importFile(data) {
    return request({
        url: '/support-3rd/bim/calculateBook/import',
        method: 'post',
        data,
    })
}

export function edit(data) {
    return request({
        url: '/support-3rd/bim/calculateBook/update',
        method: 'post',
        data,
    })
}

export function remove(data) {
    return request({
        url: '/support-3rd/bim/calculateBook/delete',
        method: 'post',
        data,
    })
}