import request from '../request'

// 查询参数列表，在查询公共参数时使用
export function fetchList(params) {
    return request({
        url: '/support-3rd/bim/item/list',
        params,
    })
}

// 查询公式参数的值
export function fetchValues(data) {
    return request({
        url: '/support-3rd/bim/item/query/formula',
        method: 'post',
        data,
    })
}

export function add(data) {
    return request({
        url: '/support-3rd/bim/item/add',
        method: 'post',
        data,
    })
}

export function edit(data) {
    return request({
        url: '/support-3rd/bim/item/update',
        method: 'post',
        data,
    })
}

export function remove(data) {
    return request({
        url: '/support-3rd/bim/item/delete',
        method: 'post',
        data,
    })
}


export function editPublicParams(data) {
    return request({
        url: "/support-3rd/bim/item/update/projectItem",
        method: 'post',
        data,
    })
}