var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane'),_c('div',{staticClass:"container"},[_c('div',{staticClass:"content"},[_c('a-tabs',{attrs:{"tab-position":"left"},model:{value:(_vm.activeTabKey),callback:function ($$v) {_vm.activeTabKey=$$v},expression:"activeTabKey"}},[_c('a-tab-pane',{key:"1",attrs:{"tab":"设计输入参数"}},[_c('a-table',{attrs:{"bordered":"","dataSource":_vm.publicParamList,"pagination":false}},[_c('a-table-column',{attrs:{"title":"序号","width":"50px","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text, row, index){return [_vm._v(" "+_vm._s(index + 1)+" ")]}}])}),_c('a-table-column',{attrs:{"title":"中文名","data-index":"name"}}),_c('a-table-column',{attrs:{"title":"英文名","data-index":"code"}}),_c('a-table-column',{attrs:{"title":"设计值"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [(String(text.valueType) === '2')?_c('span',[_c('a-input',{staticStyle:{"width":"120px"},attrs:{"size":"small","value":text.designValue},on:{"change":(e) => {
                        _vm.onChange(e.target.value, text.code);
                      }}})],1):(String(text.valueClass) === '1')?_c('span',[_c('a-input-number',{staticStyle:{"width":"120px"},attrs:{"size":"small","value":text.designValue,"precision":typeof text.decimalPrecision === 'number'
                        ? text.decimalPrecision
                        : 0},on:{"change":(e) => {
                        _vm.onChange(e, text.code);
                      }}})],1):_c('span',[_vm._v(" "+_vm._s(text.designValue)+" ")])]}}])}),_c('a-table-column',{attrs:{"title":"类型","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('DataDictFinder',{attrs:{"dictType":"bim_value_type","dictValue":String(text.valueClass)}})]}}])}),_c('a-table-column',{attrs:{"title":"单位","data-index":"unit"}}),_c('a-table-column',{attrs:{"title":"参考信息","data-index":"remark"}})],1)],1),_vm._l((_vm.bookList),function(item){return _c('a-tab-pane',{key:item.id,attrs:{"tab":item.name}},[_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('a-tabs',{attrs:{"tabBarStyle":{
                margin: '0 0 12px 0',
              }},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('a-button',{attrs:{"slot":"tabBarExtraContent","type":"primary"},on:{"click":_vm.queryParam},slot:"tabBarExtraContent"},[_vm._v(" 公式计算 ")]),_vm._l((_vm.paramTypeList),function(item){return _c('a-tab-pane',{key:item.value,attrs:{"tab":item.name}})})],2),(Array.isArray(item.bimItemList))?_c('div',[_c('a-table',{attrs:{"bordered":"","dataSource":_vm.filtered(item.bimItemList),"pagination":{
                  total: _vm.filtered(item.bimItemList).length,
                  pageSize: 10,
                  showTotal: (total) => `共 ${total} 条记录`,
                }}},[_c('a-table-column',{attrs:{"title":"序号","width":"50px","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text, row, index){return [_vm._v(" "+_vm._s(index + 1)+" ")]}}],null,true)}),_c('a-table-column',{attrs:{"title":"中文名","data-index":"name"}}),_c('a-table-column',{attrs:{"title":"英文名","data-index":"code"}}),_c('a-table-column',{attrs:{"title":"设计值"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [(String(text.valueType) === '2')?_c('span',[_c('a-input',{staticStyle:{"width":"120px"},attrs:{"size":"small","value":text.designValue,"disabled":text.disabled},on:{"change":(e) => {
                            _vm.onChangeParam(e.target.value, item.id, text.id);
                          }}})],1):(String(text.valueClass) === '1')?_c('span',[_c('a-input-number',{staticStyle:{"width":"120px"},attrs:{"size":"small","value":text.designValue,"disabled":text.disabled,"precision":typeof text.decimalPrecision === 'number'
                            ? text.decimalPrecision
                            : 0},on:{"change":(e) => {
                            _vm.onChangeParam(e, item.id, text.id);
                          }}})],1):_c('span',[_vm._v(" "+_vm._s(text.designValue)+" ")])]}}],null,true)}),_c('a-table-column',{attrs:{"title":"类型","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('DataDictFinder',{attrs:{"dictType":"bim_value_type","dictValue":String(text.valueClass)}})]}}],null,true)}),_c('a-table-column',{attrs:{"title":"单位","data-index":"unit"}}),_c('a-table-column',{attrs:{"title":"参考信息","data-index":"remark"}})],1)],1):_vm._e()],1)],1)})],2),_c('div',{staticClass:"center",staticStyle:{"margin-top":"40px"}},[_c('a-space',[_c('a-button',{attrs:{"type":"primary","loading":_vm.saveLoading},on:{"click":_vm.handleSubmit}},[_vm._v(" "+_vm._s(!_vm.writePublicParam || _vm.bookList.length !== _vm.bookRawList.length ? "下一步" : "立即创建")+" ")]),_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")])],1)],1)],1),_c('div',{staticClass:"cart"},[_vm._m(0),_vm._l((_vm.bookList),function(item){return _c('div',{key:item.id,staticClass:"card"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.name))]),(_vm.filteredValueClass(item.bimItemList).length > 0)?_c('div',_vm._l((_vm.filteredValueClass(item.bimItemList)),function(element){return _c('div',{key:element.id,staticClass:"between item"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(element.name))]),_c('div',{staticClass:"value"},[_vm._v(" "+_vm._s(element.designValue)+_vm._s(element.unit)+" ")])])}),0):_c('div',[_vm._v("暂无数据")])])})],2)]),_c('a-modal',{attrs:{"visible":_vm.visible,"title":"选择状态"},on:{"ok":_vm.ok,"cancel":_vm.cancel}},[_c('a-select',{staticStyle:{"width":"320px"},attrs:{"placeholder":"审批类型","mode":"tags"},model:{value:(_vm.stateCode),callback:function ($$v) {_vm.stateCode=$$v},expression:"stateCode"}},_vm._l((_vm.statusList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('img',{attrs:{"src":require("../../../assets/param_icon.png"),"alt":""}}),_c('span',[_vm._v("关联参数")])])
}]

export { render, staticRenderFns }