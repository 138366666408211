import request from '../request'

export function fetchList(params) {
    return request({
        url: '/support-3rd/bim/task/list',
        params,
    })
}

export function fetchDetail(params) {
    return request({
        url: '/support-3rd/bim/task/query/' + params.id,
    })
}

export function add(data) {
    return request({
        url: '/support-3rd/bim/task/add',
        method: 'post',
        data,
    })
}

export function remove(data) {
    return request({
        url: '/support-3rd/bim/task/delete',
        method: 'post',
        data,
    })
}

export function kill(data) {
    return request({
        url: '/support-3rd/bim/task/kill',
        method: 'post',
        data,
    })
}